export default {
    //资讯
    queryNewsInfoPage(param, next) {
        axios
            .post("/web/news/NewsInfo/queryPage", param)
            .then((res) => {
                next(res);
            });
    },
    getNewsInfo(param, next) {
        axios
            .post("/web/news/NewsInfo/get", param)
            .then((res) => {
                next(res);
            });
    },
    getNewsInfoAround(param, next) {
        axios
            .post("/web/news/NewsInfo/getAroundInfo", param)
            .then((res) => {
                next(res);
            });
    },
    //产品
    queryProInfoPage(param, next) {
        axios
            .post("/web/products/ProInfo/queryPage", param)
            .then((res) => {
                next(res);
            });
    },
    getProInfo(param, next) {
        axios
            .post("/web/products/ProInfo/get", param)
            .then((res) => {
                next(res);
            });
    },
    //order
    submitOrderInfo(param, next) {
        axios
            .post("/web/order/OrderInfo/submit", param)
            .then((res) => {
                next(res);
            });
    },
    queryOrderInfoPage(param, next) {
        axios
            .post("/web/order/OrderInfo/queryPage", param)
            .then((res) => {
                next(res);
            });
    },
    //SMS
    smsSend(param, next) {
        axios
            .post("/web/system/Sms/send", param)
            .then((res) => {
                next(res);
            });
    },
    queryOrderAuth(param, next) {
        axios
            .post("/web/system/Sms/queryOrderAuth", param)
            .then((res) => {
                next(res);
            });
    },
};